import React from 'react'
import '../css/animation-loader.css'
import {PREVIEW_TYPE} from "../utils/constants";

const AnimationLoader = (props) => {
    return (
        <div className="loader" style={{
            height: props.previewType === PREVIEW_TYPE.PHONE ? '534px' : props.previewType === PREVIEW_TYPE.TABLET ? '435px' : props.previewType === PREVIEW_TYPE.DESKTOP ? '-webkit-fill-available' : '100vh',
            width: props.previewType === PREVIEW_TYPE.PHONE ? '282px' : props.previewType === PREVIEW_TYPE.TABLET ? '310px' : props.previewType === PREVIEW_TYPE.DESKTOP ? '-webkit-fill-available' : '100vw',
            position: 'fixed',
            backgroundColor: '#f5f5f5',
            zIndex: '10',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div className="boxes">
                <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className="box">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default AnimationLoader