import {combineReducers, createStore} from "redux";
import answerCountReducer from "../reducers/answerCountReducer";
import screensReducer from "../reducers/screensReducer";
import themeReducer from "../reducers/themeReducer";
import formDataReducer from "../reducers/formDataReducer";
import questionsReducer from "../reducers/questionsReducer";
import formLoginReducer from "../reducers/formLoginReducer";

const forqStore = createStore(combineReducers({
    themeReducer,
    screensReducer,
    formDataReducer,
    formLoginReducer,
    questionsReducer,
    answerCountReducer
}), process.env.REACT_APP_ENV_IS_REACT_DEV_TOOLS === "true" ? (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) : undefined);

export default forqStore;
