const initialState = {
    themeColor: null,
    themeImageBlur: 0
}

export const THEME_ACTION_TYPE = {
    SET_THEME_COLOR: 'SET_THEME_COLOR',
    SET_THEME_IMAGE_BLUR: 'SET_THEME_IMAGE_BLUR'
}

const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case (THEME_ACTION_TYPE.SET_THEME_COLOR):
            return {
                ...state,
                themeColor: action.payload
            }
        case (THEME_ACTION_TYPE.SET_THEME_IMAGE_BLUR):
            return {
                ...state,
                themeImageBlur: action.payload
            }
        default:
            return state
    }
}

export default themeReducer