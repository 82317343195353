const STATUS_PAGE_PATH = {
    BLOCKED: '/page?status=blocked',
    RESPONDED: '/page?status=responded',
    PENDING: '/page?status=pending',
    INACTIVE: '/page?status=inactive',
    ERROR: '/page?status=error',
    ANSWERED: '/page?status=answered',
    HIDDEN: '/page?status=hidden'
}

export default STATUS_PAGE_PATH