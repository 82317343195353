import {ACTION_TYPE} from "../utils/utils";

const initialState = {
    answerCount: 0,
    fileUploadAnswerCount: 0
};

const answerCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case (ACTION_TYPE.SET_FILE_UPLOAD_ANSWER_COUNT):
            return {
                ...state,
                fileUploadAnswerCount: action.payload
            }
        case ACTION_TYPE.SET_ANSWER_COUNT:
            return {
                ...state,
                answerCount: action.payload
            }
        default:
            return state;
    }
}

export default answerCountReducer
