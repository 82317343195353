import * as jsonWebToken from "jsonwebtoken";

const initialState = {
    isSignIn: true,
    responderEmailId: window?.localStorage?.getItem("responder_token") ? jsonWebToken.decode(window?.localStorage?.getItem("responder_token"))?.responder_email_id : undefined
}

export const FORM_LOGIN_ACTION_TYPE = {
    SET_RESPONDER_EMAIL: 'SET_RESPONDER_EMAIL'
}

const formLoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case (FORM_LOGIN_ACTION_TYPE.SET_RESPONDER_EMAIL):
            return {
                ...state,
                responderEmailId: action.payload
            }
        default:
            return state;
    }
}

export default formLoginReducer
