import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";

export function initializeBugTracker() {
    Sentry.init({
        dsn: "https://093077dbff4041738e4d16bee77e7452@o588651.ingest.sentry.io/5739083",
        release: "react-form-5feb2022",
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.05,
    });
}
