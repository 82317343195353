const initialState = {
    questions: null,
    filesToUpload: [],
    filesToDelete: [],
    fileUploadComplete: false,
    questionComponentList: []
}

export const QUESTIONS_ACTION_TYPE = {
    SET_QUESTIONS: 'SET_QUESTIONS',
    SET_FILES_TO_UPLOAD: 'SET_FILE_TO_UPLOAD',
    SET_FILES_TO_DELETE: 'SET_FILES_TO_DELETE',
    SET_FILE_UPLOAD_COMPLETE: 'SET_FILE_UPLOAD_COMPLETE',
    SET_QUESTION_COMPONENT_LIST: 'SET_QUESTION_COMPONENT_LIST'
}

const questionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case (QUESTIONS_ACTION_TYPE.SET_FILE_UPLOAD_COMPLETE):
            return {
                ...state,
                fileUploadComplete: action.payload
            }
        case (QUESTIONS_ACTION_TYPE.SET_FILES_TO_UPLOAD):
            return {
                ...state,
                filesToUpload: action.payload
            }
        case (QUESTIONS_ACTION_TYPE.SET_FILES_TO_DELETE):
            return {
                ...state,
                filesToDelete: action.payload
            }
        case (QUESTIONS_ACTION_TYPE.SET_QUESTIONS):
            return {
                ...state,
                questions: action.payload
            }
        case (QUESTIONS_ACTION_TYPE.SET_QUESTION_COMPONENT_LIST):
            return {
                ...state,
                questionComponentList: action.payload
            }
        default:
            return state
    }
}

export default questionsReducer
