const initialState = {
    loading: true,
    formData: null,
    storageUsed: 0,
    showForqTimer: false,
    formResponses: [],
    useInfoData: null,
    editResponseId: null,
    showClearPopUp: false,
    showAbuseWarning: true,
    showSubmitButton: true,
    responderResponses: [],
    showFormPageTimer: false,
    totalUserStorage: null,
    totalUserResponseCount: null
}

export const FORM_DATA_ACTION_TYPE = {
    SET_LOADING: 'SET_LOADING',
    SET_FORM_DATA: 'SET_FORM_DATA',
    SET_STORAGE_USED: 'SET_STORAGE_USED',
    SET_FORM_RESPONSES: 'SET_FORM_RESPONSES',
    SET_USER_INFO_DATA: 'SET_USER_INFO_DATA',
    SET_SHOW_FORQ_TIMER: 'SET_SHOW_FORQ_TIMER',
    SET_EDIT_RESPONSE_ID: 'SET_EDIT_RESPONSE_ID',
    SET_SHOW_CLEAR_POPUP: 'SET_SHOW_CLEAR_POPUP',
    SET_SHOW_ABUSE_WARNING: 'SET_SHOW_ABUSE_WARNING',
    SET_SHOW_SUBMIT_BUTTON: 'SET_SHOW_SUBMIT_BUTTON',
    SET_RESPONDER_RESPONSES: 'SET_RESPONDER_RESPONSES',
    SET_SHOW_FORM_PAGE_TIMER: 'SET_SHOW_FORM_PAGE_TIMER',
    SET_TOTAL_USER_STORAGE: "SET_TOTAL_USER_STORAGE",
    SET_TOTAL_USER_RESPONSE_COUNT: "SET_TOTAL_USER_RESPONSE_COUNT"
}

const formDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case (FORM_DATA_ACTION_TYPE.SET_LOADING):
            return {
                ...state,
                loading: action.payload
            }
        case (FORM_DATA_ACTION_TYPE.SET_STORAGE_USED):
            return {
                ...state,
                storageUsed: action.payload
            }
        case (FORM_DATA_ACTION_TYPE.SET_USER_INFO_DATA):
            return {
                ...state,
                userInfoData: action.payload
            }
        case (FORM_DATA_ACTION_TYPE.SET_FORM_RESPONSES):
            return {
                ...state,
                formResponses: action.payload
            }
        case (FORM_DATA_ACTION_TYPE.SET_FORM_DATA):
            return {
                ...state,
                formData: action.payload
            }
        case (FORM_DATA_ACTION_TYPE.SET_SHOW_FORQ_TIMER):
            return {
                ...state,
                showForqTimer: action.payload
            }
        case (FORM_DATA_ACTION_TYPE.SET_EDIT_RESPONSE_ID):
            return {
                ...state,
                editResponseId: action.payload
            }
        case (FORM_DATA_ACTION_TYPE.SET_SHOW_CLEAR_POPUP):
            return {
                ...state,
                showClearPopUp: action.payload
            }
        case (FORM_DATA_ACTION_TYPE.SET_SHOW_ABUSE_WARNING):
            return {
                ...state,
                showAbuseWarning: action.payload
            }
        case (FORM_DATA_ACTION_TYPE.SET_SHOW_SUBMIT_BUTTON):
            return {
                ...state,
                showSubmitButton: action.payload
            }
        case (FORM_DATA_ACTION_TYPE.SET_RESPONDER_RESPONSES):
            return {
                ...state,
                responderResponses: action.payload
            }
        case (FORM_DATA_ACTION_TYPE.SET_SHOW_FORM_PAGE_TIMER):
            return {
                ...state,
                showFormPageTimer: action.payload
            }
        case (FORM_DATA_ACTION_TYPE.SET_TOTAL_USER_RESPONSE_COUNT):
            return {
                ...state,
                totalUserResponseCount: action.payload
            }
        case (FORM_DATA_ACTION_TYPE.SET_TOTAL_USER_STORAGE):
            return {
                ...state,
                totalUserStorage: action.payload
            }
        default:
            return state
    }
}

export default formDataReducer