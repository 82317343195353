import {getQuestionCategory, QUESTION_CATEGORY} from "../../shared/utils/constants";
import * as mongoObjectID from "bson-objectid"

export const FORM_SCREEN = {
    WELCOME_PAGE: 0,
    FORM_PAGE: 1,
    THANK_PAGE: 2,
    QUIZ_USER_INFO: 3,
    ABUSE_WARNING: 4,
    FORM_USER_INFO: 5,
    PASSCODE_PAGE: 6,
    COLLECT_EMAIL_PAGE: 7,
    FORM_AVAILABLE_TIMER: 8,
    FORM_EDIT_RESPONSE: 9,
    RESPONSE_HISTORY: 10
}

export const THEME_TYPE = {
    DARK: "dark_theme",
    CLASSIC: "classic_theme",
}

export function isMinWidth600() {
    return window.matchMedia("(min-width: 600px)").matches;
}

export function createFormDataTempId(oldFormData) {
    const questions = oldFormData.pages[0].questions;
    let currentQuestion;
    let currentChoices;
    for (let i = 0; i < questions?.length; i++) {
        currentQuestion = questions[i];
        if (!currentQuestion._id) {
            currentQuestion._id = mongoObjectID().toString() + "tempId"
        }
        if (getQuestionCategory(currentQuestion.type) === QUESTION_CATEGORY.CHOICE_TYPE) {
            currentChoices = currentQuestion.choices;
            for (let j = 0; j < currentChoices?.length; j++) {
                if (!currentChoices[j]._id) {
                    currentChoices[j]._id = mongoObjectID().toString() + "tempId"
                }
            }
        }
    }
    return questions;
}

export const ACTION_TYPE = {
    SET_ANSWER_COUNT: "SET_ANSWER_COUNT",
    SET_FILE_UPLOAD_ANSWER_COUNT: 'SET_FILE_UPLOAD_ANSWER_COUNT'
}

export const isCalledFromAppResponseCollector = () => {
    const url = new URL(window.location.href);
    if (window.location.search && !url.searchParams.get("prefilleddata")) {
        let data = window.location.href.split('?')[1]
        let currentPreviewFormData = JSON.parse('{"' + decodeURI(data).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\s/g, '') + '"}')
        return currentPreviewFormData.appresponsecollector === 'true';
    } else return false
}

export const getDateDiff = (olderDateString, newerDateString) => {
    try {
        const olderDate = new Date(Number(olderDateString)).getTime()
        const newerDate = new Date(Number(newerDateString)).getTime()

        const diffTime = Math.abs(olderDate - newerDate)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

        return `${diffDays} Days`
    } catch (e) {
        console.log(e)
        return ""
    }
}
